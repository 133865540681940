<template>
    <div class="up_content_inner up_support_video">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4">
                <h2 class="up_page_title">Support</h2>
            </div>
            <div class="up_inner_container p-grid">
                <div class="p-col-5 up_sp_faq p-pr-4">
                    <h3>Frequently Asked Questions</h3>
                    <Accordion>
                        <AccordionTab>
                            <template #header>
                                Will Truabilities make my website fully accessible?
                            </template>
                            Installing the Trueabilities accessibility widget is your first step in full compliance. To better understand accessibility and data compliance for the web, contact our team today for resources about ADA, GDPR, and CCPA, and a full compliance roadmap.
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                Will the Truabilities code work on my website?
                            </template>
                            Yes. The Truabilities widget generates a few short lines of code that can be installed in a matter of minutes. Truabilities has developed next-generation technology that doesn’t rely on cookies and doesn’t slow page loads.
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                Can someone at Truabilities install the widget for me?
                            </template>
                            The Truabilities widget is fully self-serve and easy to install, with just a short line of code needing to be pasted into your website’s header field. If you need additional support installing the widget, contact our Support team and we can help.
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                I have seen other accessibility widgets that are free. How is Truabilities' solution different?
                            </template>
                            Web accessibility is an important part of doing business in a digital world. The Truabilities widget facilitates accessibility between your web content and your site visitors, while using next-generation technology that doesn’t use cookies and doesn’t slow down page load times.
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                Installation Instructions
                            </template>
                            <table class="up_website_creation">
                                <tbody>
                                    <tr>
                                        <td>Step 1</td>
                                        <td>Copy the <code>&lt;script&gt;</code> tag.</td>
                                    </tr>
                                    <tr>
                                        <td>Step 2</td>
                                        <td>Open your file manager or any FTP client, find the <code>&lt;/body&gt;</code> tag in your website source code.</td>
                                    </tr>
                                    <tr>
                                        <td>Step 3</td>
                                        <td>Paste the <code>&lt;script&gt;</code> before the immediate closing of <code>&lt;/body&gt;</code> tag.</td>
                                    </tr>
                                    <tr>
                                        <td>Step 4</td>
                                        <td>Save this file, upload the file to server if necessary.</td>
                                    </tr>
                                    <tr>
                                        <td>Step 5</td>
                                        <td>Reload the website after clearing all you cookies and cached data from browser and from your server if it has caching mechanism on place.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div class="p-col-7 up_sp_videos">
                    <h3>How To Videos</h3>
                    <div class="p-grid">
                        <div class="p-col-4 p-mb-3">
                            <div class="up_sp_video_box" :data-poster="imageUrlFrontEnd + 'dist/img/add-website.jpg'" @click="openVideoModal('https://truabilities-prod.s3-us-west-1.amazonaws.com/media/demo-video/Add+Website.mp4', $event)">
                                <figure>
                                    <img :src="imageUrlFrontEnd + 'dist/img/add-website.jpg'" alt="add-website">
                                </figure>
                                <h5>Add New Site</h5>
                            </div>
                        </div>
                        <div class="p-col-4 p-mb-3">
                            <div class="up_sp_video_box" :data-poster="imageUrlFrontEnd + 'dist/img/manage-website-poster.jpg'" @click="openVideoModal('https://truabilities-prod.s3-us-west-1.amazonaws.com/media/demo-video/Manage+Website.mp4', $event)">
                                <figure>
                                    <img :src="imageUrlFrontEnd + 'dist/img/manage-website-poster.jpg'" alt="add-website">
                                </figure>
                                <h5>Manage Website</h5>
                            </div>
                        </div>
                        <div class="p-col-4 p-mb-3">
                            <div class="up_sp_video_box" :data-poster="imageUrlFrontEnd + 'dist/img/add-website.jpg'" @click="openVideoModal('https://truabilities-prod.s3-us-west-1.amazonaws.com/media/demo-video/Manage+Branding.mp4', $event)">
                                <figure>
                                    <img :src="imageUrlFrontEnd + 'dist/img/add-website.jpg'" alt="add-website">
                                </figure>
                                <h5>Manage Branding</h5>
                            </div>
                        </div>
                        <div class="p-col-4">
                            <div class="up_sp_video_box" :data-poster="imageUrlFrontEnd + 'dist/img/billing-video-poster.jpg'" @click="openVideoModal('https://truabilities-prod.s3-us-west-1.amazonaws.com/media/demo-video/Billing.mp4', $event)">
                                <figure>
                                    <img :src="imageUrlFrontEnd + 'dist/img/billing-video-poster.jpg'" alt="add-website">
                                </figure>
                                <h5>Billing</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- View Website Details Modal --->
        <Dialog v-model:visible="videoModal.open" contentClass="up_custom_content" :style="{width: '1024px'}" :modal="true" @hide="close()">
            <template #header>
                <span class="p-dialog-title">
                    {{videoModal.modalHeading}}
                </span>
            </template>
            <div class="up_video_content">
                <video controls controlslist="nodownload" :poster="videoModal.posterUrl">
                <source :src= "videoModal.videoUrl" type="video/mp4">
                </video>
            </div>            
        </Dialog>
        <!--- View Website Details Modal --->
    </div>
</template>

<script>
import { reactive } from 'vue';
import { imageUrlFrontEnd, s3RootUrl } from '../../../../config/constant';

export default {
    name: "SupportVideos",
    setup(){
        let videoModal = reactive({
            open: false,
            videoUrl: null,
            posterUrl: null,
            modalHeading: null
        });

        const openVideoModal = (url , evt) => {
            videoModal.videoUrl = url;
            videoModal.posterUrl = evt.target.dataset.poster;
            videoModal.modalHeading = evt.target.children[1].textContent;
            videoModal.open = true;
        }

        const close = () => {
            videoModal.videoUrl = null;
            videoModal.posterUrl = null;
            videoModal.modalHeading = null;
        }

        return{
            imageUrlFrontEnd,
            s3RootUrl,
            videoModal,
            openVideoModal,
            close
        }
    }
}
</script>


<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/supportVideos/SupportVideos';
</style>